<template>
  <!-- Collective Logo -->
  <safe-img
    :src="computedLogo"
    :placeholder="avatarPlaceholder"
    class="avatar"
    :style="size?`width:${size}px; height:${size}px`:''"
  />
</template>

<script>
import AvatarPlaceholder from '@/assets/images/placeholders/light/icono-general-light.png';
import SafeImg from '@core/components/safe-img/SafeImg.vue';
import { getImageResource } from '@/@core/utils/image-utils';

export default {
  components: { SafeImg },
  props: {
    logo: {
      type: String,
      default: AvatarPlaceholder,
    },
    size: {
      type: [String, Number],
      default: 40,
    },
  },
  data() {
    return {
      hasImageFailed: false,
    };
  },
  computed: {
    computedLogo() {
      return getImageResource(this.logo);
    },
    avatarPlaceholder() {
      return AvatarPlaceholder;
    },
  },
};
</script>
<style lang="scss" scoped>
.avatar {
  object-fit: cover;
  border-radius: 50%;
  background: white;
}
</style>
