<template>
  <div class="px-2 px-sm-3 px-md-2">
    <div
      v-for="(community, index) in allCommunities"
      v-show="allCommunities.length>0"
      :key="index"
    >
      <div v-if="index !== 0" class="navbar-arrow-container-small">
        <div />
        <div />
      </div>
      <b-link

        class="text-dark d-flex align-items-center my-50 w-100"
        :href="getCollectiveUrl(community)"
      >
        <!-- Avatar -->
        <collective-logo :logo="getImage(community)" />
        <!-- Name and headline -->
        <div class="ml-1 d-more-than-lg w-100">
          <p class="font-weight-extrabold mb-0 truncated-text">
            {{ translate (community.name) }}
          </p>
        <!--   <p v-if="community.headline" class="text-muted small mb-0 truncated-text">
            {{ community.headline }}
          </p> -->
        </div>
        <!-- End web -->
      </b-link>
    </div>
  </div>
</template>

<script>
import CollectiveLogo from '@core/components/collective-logo/CollectiveLogo.vue';
import { getCollectiveUrl } from '@core/utils/collective';
import { getImageResource } from '@/@core/utils/image-utils';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';

export default {
  components: { CollectiveLogo },
  props: {
    allCommunities: {
      type: Array,
      required: true,
    },
  },
  methods: {
    translate(field) {
      return translateTranslationTable(this.$store.state.locale.currentLocale, field);
    },
    getImage(collective) {
      if (collective.logoURL != null) {
        return getImageResource(collective.logoURL);
      }
      if (collective.header != null && collective.header.logo_url != null) {
        return getImageResource(collective.header?.logo_url);
      }
      return '';
    },
    getCollectiveUrl(collective) {
      return getCollectiveUrl(collective);
    },
  },
};
</script>
